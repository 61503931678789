// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d2af54,.d2af54>div{position:relative}.d2af54>div{-webkit-box-sizing:border-box;box-sizing:border-box;padding:10px 10px;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviews": "d2af54"
};
export default ___CSS_LOADER_EXPORT___;
